import structuredClone from '@ungap/structured-clone';
import { PRODUCT_FAMILY } from '@belong/types';
import { OFFER_CHANNEL_ACTION } from '@belong/types/api/offers';
import { OfferContentManager } from '../OfferContentManager';

// {MOBILE-DATA}-PLAN-PAGE: Override to find active/ATL Offers for each plan and add them to the PlanCard props
export const override = <T = any>(correlationId: string, contentManager: OfferContentManager, section: T): T => {
  const { plans, footerContent } = section as any;

  // Inject ATL offers into plan cards
  const updatedPlans = plans.map(planCard => {
    const offerDetails = contentManager.getOfferForPlan(planCard.plan.productCode, OFFER_CHANNEL_ACTION.ACTIVATION);

    return {
      ...planCard,
      offerDetails
    };
  });

  // Override Terms and Conditions (extract html from campaign content if present)
  const termsAndConditions =
    contentManager.getCampaignOverride(correlationId, 'planSelectionTerms', undefined, PRODUCT_FAMILY.MOBILE_DATA_PLANS)
      ?.html ?? footerContent;

  return structuredClone({ ...section, plans: updatedPlans, footerContent: termsAndConditions });
};

export default {
  // always include base-path in paths
  path: ['/go/data-plans'],
  contentType: ['sectionPlanSummary'],
  override
};
