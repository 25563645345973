import { logger } from '@belong/logging';
import type { Entry } from '@belong/types';

import { isCampaignEntry } from './isCampaignEntry';
import { isDefaultEntry } from './isDefaultEntry';

export const findEntryForPromotion = (entries?: Entry<any>[], filterKey?: string): Entry<any> | undefined => {
  const unfiltered = entries || [];
  const defaultEntry = unfiltered.find(entry => isDefaultEntry(entry)) ?? unfiltered[0];

  if (filterKey) {
    const promoEntry = unfiltered.find(entry => isCampaignEntry(entry, filterKey));

    if (promoEntry) {
      logger.info(
        `Content for entry: ${promoEntry.sys?.contentType?.sys?.id} filtered by tag that includes: ${filterKey}`
      );

      return promoEntry;
    }

    logger.error('No entry found for tag: ', filterKey, 'returning default entry');
    return defaultEntry;
  }

  return defaultEntry;
};
