import { Cookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { ENVS } from '@belong/types';
import { getCurrentEnvironment } from '@belong/env';
import type { IContentfulSettings } from '@belong/types';
import { getConfig } from '@belong/configs/next/config';

export const getContentfulSettings = (cookies?: Cookies, contentful?: IContentfulSettings): IContentfulSettings => {
  const { accessToken, environment, host, space } = contentful || getConfig().publicRuntimeConfig.contentful;
  const result = { accessToken, environment, host, space };

  if (getCurrentEnvironment() !== ENVS.PRODUCTION) {
    const cookie = cookies?.get(COOKIES.CONTENTFUL_SETTINGS);
    if (cookie) {
      Object.assign(result, cookie);
    }
  }

  return result;
};
