import { useEffect } from 'react';

import { AnalyticsEvent, EVENT_NAME } from '@belong/analytics';
import { getCurrentEnvironment } from '@belong/env';
import { ENVS } from '@belong/types';

import { useAnalyticsEvents } from './hook';

export const logEvent = (evt: AnalyticsEvent): void => {
  if (getCurrentEnvironment() === ENVS.DEVELOPMENT || getCurrentEnvironment() === ENVS.AT) {
    // eslint-disable-next-line no-console
    console.debug('%c [Analytics Event]', 'color: pink', evt.detail);
  }
};

export const useAnalyticsDebugger = (): void => {
  const analyticsEvents = useAnalyticsEvents();

  useEffect(() => {
    analyticsEvents.addEventListener(EVENT_NAME, logEvent);

    return () => {
      analyticsEvents.removeEventListener(EVENT_NAME, logEvent);
    };
  }, [analyticsEvents]);
};
