import { Entry } from '@belong/types';

import { CONTENT_LOOKUP_KEY, CAMPAIGN_TAG } from './constants';

interface ITag {
  id: string;
  name: string;
}
interface IItem {
  contentfulMetadata: {
    tags: ITag[];
  };
}

export const isDefaultEntry = (entry: Entry<unknown> | IItem): boolean => {
  if ('contentfulMetadata' in entry) {
    return isDefaultEntryGQL(entry);
  }

  const data = entry.metadata;

  // is default of no tags
  if (!data?.tags || !data?.tags?.length) {
    return true;
  }

  // is default if have tags but does not include Lookup key flag
  return !data.tags.some(tag => tag.sys.id.toUpperCase().includes(CONTENT_LOOKUP_KEY));
};

export const isDefaultEntryGQL = (item: IItem): boolean => {
  const data = item.contentfulMetadata;

  // is default of no tags
  if (!data?.tags || !data?.tags?.length) {
    return true;
  }

  // is default if have tags but does not include Campaign flag
  return (
    !data.tags.some(tag => tag.id.toUpperCase().includes(CONTENT_LOOKUP_KEY)) &&
    !data.tags.some(tag => tag.id.toUpperCase().includes(CAMPAIGN_TAG))
  );
};
